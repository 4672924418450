import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Card, Table, Spinner } from "react-bootstrap";
import styled from "styled-components";

import { history, currencyFormatter, getProductInitData, getUUID, fillProductInitData } from "_helpers";
import { variantsActions, customerActions } from "_actions";

import AddProductForm from "components/OrderDetails/CreateOrder/AddProductForm";
import ConfirmationModal from "components/ConfirmationModal";
import CreateOrderModal from "components/CreateOrderModal";

const StyledLabel = styled(Form.Label)`
  color: black !important;
`;

const initData = {
  id: "",
  type: "",
  category: "",
  processingOption: "",
  data: {},
};

const CreateOrder = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const [addedProducts, setAddedProducts] = useState([]);
  const [productDetails, setProductDetails] = useState(initData);
  const [isEditing, setIsEditing] = useState(false);
  const [showDebug, setShowDebug] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState("");
  const [showCreateOrderModal, setShowCreateOrderModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState([]);

  const loadingOrderData = useSelector(({ customers }) => customers.loading);
  const loadingVariants = useSelector(({ variants }) => variants.loading);
  const { allPrices, allProductCodes, allProcessingOptions } = useSelector(({ variants }) => variants);
  const parentOrderDetails = useSelector(({ customers }) => customers.orderdata);

  const loading = loadingOrderData || loadingVariants;

  const pricesArray = Object.values(allPrices)?.flat() || [];

  const mainProduct =
    parentOrderDetails?.products?.find((item) => {
      item?.code?.main;
    }) ||
    parentOrderDetails?.products?.[0] ||
    null;

  const newOrderBody = useMemo(() => {
    const newProducts = addedProducts.map((product) => {
      return { ...product.data, processingOption: product?.processingOption || "STANDARD" };
    });

    return {
      order: {
        processingOption: "UPSELL",
      },
      products: newProducts,
    };
  }, [addedProducts]);

  const getPriceObject = (code, option) => {
    return pricesArray.find((price) => price.code.code === code && price.option === option);
  };

  function getProductCodeObject(category, productCode) {
    const productCodesByCategory = allProductCodes[category] || [];

    return productCodesByCategory.find((code) => code?.code === productCode);
  }

  const handleResetProduct = (category = "") => {
    const defaultCategory = mainProduct?.code?.category || "";
    const currentCategory = category || defaultCategory || "";

    const processingOptionsByCategory = allProcessingOptions[currentCategory] || [];
    const defaultProcessingOption =
      processingOptionsByCategory?.find((item) => item?.code === "UPSELL")?.code || "STANDARD";

    const newObj = {
      ...initData,
      category: category || defaultCategory || "",
      processingOption: defaultProcessingOption || "",
    };

    setProductDetails(newObj);
    // localStorage.removeItem("@CreateOrder/productData");
  };

  const handleRemoveAll = () => {
    setAddedProducts([]);
    handleResetProduct();
    setIsEditing(false);
    setErrors([]);
    setValidated(false);
    localStorage.removeItem("@CreateOrder/addedProducts");
    // localStorage.removeItem("@CreateOrder/productData");
  };

  const calculatePrices = useCallback(() => {
    const prices = addedProducts.map((product) => {
      const productPriceObj = getPriceObject(product.type, product.processingOption);

      return productPriceObj?.price || 0;
    });

    return prices;
  }, [addedProducts, allPrices]);

  const calculatedPricesArray = calculatePrices();
  const totalPrice = calculatedPricesArray.reduce((acc, price) => acc + price, 0);

  const handleCreateOrder = () => {
    // history.push(`/admin/orders/${orderId}`);
    // dispatch(alertActions.success("Related order was added"));
    // localStorage.removeItem("@CreateOrder/addedProducts");
    setShowCreateOrderModal(true);
  };

  const handleAddProduct = () => {
    const newProduct = productDetails;
    newProduct.id = getUUID();
    setAddedProducts([...addedProducts, productDetails]);
    handleResetProduct(productDetails.category);
    // localStorage.removeItem("@CreateOrder/productData");
  };

  const handleRemoveProduct = (productId) => {
    const filteredAddedProducts = addedProducts.filter(({ id }) => id !== productId);
    setAddedProducts(filteredAddedProducts);
  };

  const handleChangeProduct = ({ target: { name, value } }) => {
    if (name === "type") {
      if (isEditing) {
        return;
      }

      const codeObj = getProductCodeObject(productDetails?.category, value) || {};
      const newProductObj = { ...productDetails, [name]: value };

      // newProductObj.data = { ...productDetails.data, code: codeObj };
      const initValues = getProductInitData(codeObj, productDetails.data);
      const filledInitValues = fillProductInitData(initValues, parentOrderDetails);

      newProductObj.data = filledInitValues;
      setProductDetails(newProductObj);

      return;
    }

    if (name === "processingOption") {
      const newProductObj = { ...productDetails, [name]: value };
      newProductObj.data = { ...productDetails.data, [name]: value };
      setProductDetails(newProductObj);

      return;
    }

    setProductDetails({ ...productDetails, [name]: value });
  };

  const handleChangeProductData = (newData) => {
    setProductDetails({ ...productDetails, data: { ...productDetails.data, ...newData } });
  };

  // const handleProcessingOption = ({ target: { value } }) => setProcessingOption(value);

  const handleUpdateProduct = () => {
    const newAddedProducts = addedProducts.map((product) => {
      if (product.id === productDetails.id) {
        return productDetails;
      }
      return product;
    });

    setAddedProducts(newAddedProducts);
    setIsEditing(false);
    handleResetProduct();
  };

  const handleEditProduct = (productId) => {
    const editableProduct = addedProducts.find(({ id }) => id === productId);
    setProductDetails(editableProduct);
    setIsEditing(true);
  };

  const handleCancelEditing = () => {
    handleResetProduct();
    setIsEditing(false);
    setErrors([]);
    setValidated(false);
  };

  const renderProductRow = (product, idx) => {
    const { id, data } = product;

    const borderColor = showRemoveConfirm === id ? "red" : "orange";
    const showBorder = productDetails.id === id || showRemoveConfirm === id;

    function renderProductPrice() {
      let resultStr = currencyFormatter(calculatedPricesArray[idx] || "0");

      if (data?.code?.code.includes("StateFee")) {
        resultStr += " + State fee";
      }

      return resultStr;
    }

    return (
      <tr
        key={`added-product-${id}`}
        style={{
          boxShadow: showBorder ? `inset 0 0 0 2px ${borderColor}` : "",
        }}
      >
        <td>{idx + 1}</td>
        <td>
          <div>{data?.code?.customerName || ""}</div>
          <div style={{ color: "gray" }}>({data?.code?.code || ""})</div>
        </td>
        <td>{renderProductPrice()}</td>
        <td>
          <Button
            variant="warning"
            className="btn btn-sm mr-2"
            disabled={productDetails.id === id || loading}
            onClick={() => {
              handleEditProduct(id);
            }}
          >
            Edit <i className="fa fa-edit" />
          </Button>
          <Button
            variant="danger"
            className="btn btn-sm"
            disabled={productDetails.id === id || loading}
            onClick={() => {
              setShowRemoveConfirm(id);
            }}
          >
            Delete <i className="fa fa-trash" />
          </Button>
        </td>
      </tr>
    );
  };

  function renderTotalPrice() {
    let resultStr = currencyFormatter(totalPrice || "");

    const hasStateFeeProducts = addedProducts?.some((product) => {
      return product?.type?.includes("StateFee");
    });

    if (hasStateFeeProducts) {
      resultStr += " + State fee";
    }

    return resultStr;
  }

  useEffect(() => {
    const prevOrderId = localStorage.getItem("@CreateOrder/prevOrderId");
    const data1 = localStorage.getItem("@CreateOrder/addedProducts");
    // const data2 = localStorage.getItem("@CreateOrder/productData");

    if (prevOrderId !== orderId) {
      localStorage.setItem("@CreateOrder/prevOrderId", orderId);
      localStorage.removeItem("@CreateOrder/addedProducts");
      // localStorage.removeItem("@CreateOrder/productData");
    } else {
      if (data1) setAddedProducts(JSON.parse(localStorage.getItem("@CreateOrder/addedProducts")));
      // if (data2) setProductDetails(JSON.parse(localStorage.getItem("@CreateOrder/productData")));

      // if (data1 !== "[]" || data2 != JSON.stringify(initData))
      //   dispatch(alertActions.info("Previous changes was recovered from storage"));
    }
  }, []);

  useEffect(() => {
    dispatch(variantsActions.getAllProductCodes());
    dispatch(variantsActions.getAllProcessingOptions());
    dispatch(variantsActions.getAllPrices());
    dispatch(customerActions.getprimaryactivity());
  }, [dispatch]);

  useEffect(() => {
    orderId && dispatch(customerActions.getOrder(orderId));
  }, [orderId]);

  useEffect(() => {
    if (!loading && parentOrderDetails) {
      const isOrderPaid = ["Success", "FullRefund", "PartialRefund"].includes(mainProduct?.paymentStatus);

      if (mainProduct && !isOrderPaid) {
        history.push(`/admin/orders/${orderId}`);
      }

      handleResetProduct();
    }
  }, [parentOrderDetails, loading]);

  // useEffect(() => {
  //   localStorage.setItem("@CreateOrder/productData", JSON.stringify(productDetails));
  // }, [productDetails]);

  useEffect(() => {
    localStorage.setItem("@CreateOrder/addedProducts", JSON.stringify(addedProducts));
  }, [addedProducts]);

  return (
    <Container fluid className="py-5 mt-5" style={{ backgroundColor: "#f5f5f5" }}>
      <Row className="mx-0">
        <Button
          className="btn btn-primary mb-2"
          onClick={() => {
            history.push(`/admin/orders/${orderId}`);
          }}
        >
          <i className="fa fa-arrow-left" /> &nbsp; Back
        </Button>
      </Row>

      <Row className="mt-3">
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card className="px-4 py-2" style={{ minHeight: "400px" }}>
            <AddProductForm
              isEditing={isEditing}
              validated={validated}
              errors={errors}
              setValidated={setValidated}
              setErrors={setErrors}
              productDetails={productDetails}
              handleAddProduct={handleAddProduct}
              handleChangeProduct={handleChangeProduct}
              handleChangeProductData={handleChangeProductData}
              handleUpdateProduct={handleUpdateProduct}
              handleCancelEditing={handleCancelEditing}
              handleResetProduct={handleResetProduct}
            />
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card className="w-100 py-2" style={{ minHeight: "400px" }}>
            <Row className="px-4">
              <Col sm="7" className="px-2">
                <h3>Added Products</h3>
              </Col>
              <Col sm="5">
                <Button
                  size="sm"
                  className="btn btn-danger pull-right mt-4"
                  disabled={addedProducts?.length < 1 || loading}
                  onClick={() => {
                    setShowRemoveConfirm("all");
                  }}
                >
                  Remove all&nbsp;
                  <i className="fa fa-trash" />
                </Button>
              </Col>
            </Row>

            <Table responsive bordered striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Type</th>
                  <th>Price</th>
                  <th style={{ minWidth: "175px" }}>Actions</th>
                </tr>
              </thead>
              <tbody>{addedProducts.map(renderProductRow)}</tbody>
            </Table>
            <hr />

            <Row className="px-4 mt-auto">
              <Col className="px-2">
                <Row>
                  <Col sm="7">
                    <h4 className="m-0">Order Summary:</h4>
                  </Col>
                  <Col sm="5"></Col>
                </Row>
                <Row>
                  <Col>
                    <StyledLabel>
                      Delayed Upsell For Order:{" "}
                      {loading ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      ) : (
                        <b>{`#${parentOrderDetails?.order?.crmOrderId}` || orderId || "N/A"}</b>
                      )}
                    </StyledLabel>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="name">
                      <StyledLabel>Total order price:</StyledLabel>
                      &nbsp;
                      <StyledLabel>
                        <b>{renderTotalPrice()}</b>
                      </StyledLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="btn btn-success"
                      disabled={addedProducts?.length < 1 || loading || isEditing}
                      onClick={handleCreateOrder}
                    >
                      Create order&nbsp;
                      <i className="fa fa-save" />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Button
            style={{ opacity: 0.5 }}
            variant={showDebug ? "secondary" : "light"}
            size="sm"
            onClick={() => setShowDebug(!showDebug)}
          >
            Debug&nbsp;
            {showDebug ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
          </Button>
          {showDebug && (
            <Form.Control
              readOnly
              plaintext
              as="textarea"
              style={{
                border: "1px solid lightgray",
                minHeight: "80dvh",
                fontSize: "12px",
                fontFamily: "monospace",
              }}
              value={JSON.stringify(productDetails, null, 2)}
            />
          )}
        </Col>
        <Col sm="6">
          {showDebug && (
            <Form.Control
              readOnly
              plaintext
              as="textarea"
              style={{
                marginTop: "31px",
                border: "1px solid lightgray",
                minHeight: "80dvh",
                fontSize: "12px",
                fontFamily: "monospace",
              }}
              value={JSON.stringify(addedProducts, null, 2)}
            />
          )}
        </Col>
      </Row>

      <ConfirmationModal
        show={Boolean(showRemoveConfirm)}
        handleClose={() => {
          setShowRemoveConfirm("");
        }}
        handleConfirm={() => {
          if (showRemoveConfirm === "all") {
            handleRemoveAll();
          } else {
            handleRemoveProduct(showRemoveConfirm);
          }

          setShowRemoveConfirm("");
        }}
        body={`Are you sure you want to remove ${
          showRemoveConfirm === "all" ? "all products" : "this selected product"
        }?`}
      />

      {showCreateOrderModal && (
        <CreateOrderModal
          orderId={orderId}
          body={newOrderBody}
          show={showCreateOrderModal}
          handleClose={() => {
            setShowCreateOrderModal(false);
          }}
          handleConfirm={() => {
            handleRemoveAll();
          }}
        />
      )}
    </Container>
  );
};

export default CreateOrder;
